<template>
  <div class="">
    <SiteNav />

    <section class="hero relative pt-12 pb-20 lg:pt-16 lg:pb-24">
      <div class="absolute left-0 top-0 w-full h-full" style="z-index: -1">
        <img
          src="./assets/hero-bg-with-pattern.png"
          class="absolute left-0 top-0 w-full h-full"
        />
        <img
          src="./assets/hero-blur.png"
          class="absolute left-0 top-0 w-full h-full"
        />
      </div>

      <div class="l-container">
        <div
          class="text-center flex flex-col-reverse lg:text-left lg:grid grid-cols-2 gap-8 items-center"
        >
          <div class="">
            <p class="tagline">Eazipay for BUSINESSES</p>
            <h1 class="heading level-1 mb-4">Simple Payroll</h1>
            <p class="text-summary w-4/5 mx-auto lg:mx-0 mb-6">
              Add your employee the simple way you know and run their payroll.
              All in seconds!
            </p>
            <SiteButton color="secondary" shadow to="/login">
              Start Using Free, Forever
            </SiteButton>
          </div>
          <div class="max-w-lg mx-auto">
            <img src="./assets/hero-img.png" />
          </div>
        </div>
      </div>
    </section>

    <section class="features-section py-16 lg:py-20">
      <div class="l-container">
        <ul class="feature-list">
          <li>
            <div class="media">
              <img src="./assets/enterprise-loans.png" />
            </div>
            <div class="content">
              <p class="tagline">ENTERPRISE LOANS</p>
              <h2 class="heading title">Payroll & Enterprise loans</h2>
              <p>
                Easily access loans to cover your operations or payroll, right
                on your app!
              </p>
            </div>
          </li>
          <li class="reverse">
            <div class="media">
              <img src="./assets/report.png" alt="" />
            </div>
            <div class="content">
              <p class="tagline">REPORT</p>
              <h2 class="heading title">Accurate Payroll reports & Payslips</h2>
              <p>
                For making intelligent business decisions, Get your payroll
                reports, employee payslips, tax receipts, payroll analysis,
                compliance history - all in one place!
              </p>
            </div>
          </li>
          <li class="compliance">
            <div class="media">
              <img src="./assets/compliance.png" alt="" />
            </div>
            <div class="content">
              <p class="tagline">COMPLIANCE</p>
              <h2 class="heading title">
                Register and Remit compliances easily
              </h2>
              <p>
                Register your business and employees for All your statutory
                deductions - PAYE, Pension, HMO, Insurances, NSITF, NHF.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class="payroll-calculator-section py-16 lg:py-20">
      <div class="l-container">
        <div class="lg:grid grid-cols-2 gap-8 items-center">
          <div
            ref="calculator"
            class="text-center max-w-lg mx-auto lg:max-w-none lg:text-left mb-8 lg:mb-0"
          >
            <p class="tagline">eazipay payroll calculator</p>
            <h2 class="heading level-2 mb-4">
              Run payroll in Five minutes or less
            </h2>
            <p class="text-summary lg:w-4/5">
              For making intelligent business decisions, Get your payroll
              reports, employee payslips, tax receipts, payroll analysis,
              compliance history - all in one place!
            </p>
          </div>

          <div class="">
            <div class="max-w-lg mx-auto lg:max-w-none">
              <PayrollCalculator />
            </div>
          </div>
        </div>
      </div>
    </section>

    <SiteFooter />
  </div>
</template>

<script>
import SiteNav from "../../components/SiteNav.vue";
import SiteFooter from "../../components/SiteFooter.vue";
import PayrollCalculator from "./PayrollCalculator.vue";
import SiteButton from "../../components/SiteButton.vue";
import { watch, ref, onMounted } from "vue";
export default {
  components: { SiteNav, SiteFooter, PayrollCalculator, SiteButton },

  methods: {
    async scrollToSection() {
      // Get a reference to the section element
      const section = this.$refs.calculator;

      // Ensure the section element exists
      if (section) {
        // Scroll to the section using scrollIntoView

        await this.$nextTick(() =>
          section.scrollIntoView({ behavior: "smooth", block: "center" })
        );
        // this.$nextTick(() => section.scrollIntoView({ behavior: "smooth" }));
      }
    },
  },
  async mounted() {
    this.scrollItem = true;
    // Call scrollToSection when the component is mounted
    // this.scrollToSection();
    await this.$nextTick();

    if (this.$route.query.scroll) {
      this.scrollToSection();
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

.hero {
  .heading {
    color: #11453b;
  }
}

.features-section {
  .feature-list {
    display: grid;
    grid-gap: 80px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 28px;

      .media {
        max-width: 300px;
        flex-shrink: 0;
      }

      .content {
        max-width: 500px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .title {
        font-size: 30px;
        margin-bottom: 16px;
      }
    }
  }

  li.compliance {
    margin-top: -25px; /* to reconcile the spacing at the top of the image */
  }
}

.payroll-calculator-section {
  background-color: #f0f7eb;
}

@screen lg {
  .features-section {
    .feature-list {
      grid-gap: 100px;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0;

        .media {
          width: 45%;
          max-width: unset;
          flex-shrink: 0;
        }

        .content {
          width: 50%;
          max-width: unset;
          margin-left: 0;
          margin-right: 0;
          text-align: left;
        }
        .title {
          font-size: 50px;
          margin-bottom: 16px;
        }
      }

      li.reverse {
        flex-direction: row-reverse;
      }
      li.compliance {
        margin-top: 0px; /* to */
      }
    }
  }
}
</style>
